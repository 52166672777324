// @flow

import type { NameType as NameTypeType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import SimpleEditPage from './SimpleEditPage';
import NameTypeForm from '../components/NameTypeForm';
import NameTypes from '../services/NameTypes';

type Props = EditContainerProps & Translateable & {
  item: NameTypeType
};

const Tabs = {
  details: 'details'
};

const NameType: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleEditPage
    {...props}
  >
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <NameTypeForm
        {...props}
      />
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

export default {
  component: NameType,
  onInitialize: (id: number): Promise<any> => (
    NameTypes
      .fetchOne(id)
      .then(({ data }) => data.name_type)
  ),
  onSave: (nameType: NameTypeType): Promise<any> => (
    NameTypes
      .save(nameType)
      .then(({ data }) => data.name_type)
  )
};
