// @flow

const ISOStringToDatetime = (dateString: ?string): string | null => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  return date && date.toISOString().slice(0, -1);
};

const datetimeToISOString = (dateString: ?string): string | null => {
  if (!dateString) {
    return null;
  }
  const date = new Date(`${dateString}Z`);
  return date && date.toISOString();
};

const formatDatetime = (dateString: ?string): string | null => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  return date && date.toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale, { timeZone: 'UTC', timeZoneName: 'shortOffset' });
};

const formatDate = (dateString: ?string): string | null => {
  const date = withoutTime(dateString);
  return date && date.toLocaleDateString();
};

const withoutTime = (dateString: ?string): Date | null => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  return date;
};

export default {
  datetimeToISOString,
  formatDate,
  formatDatetime,
  ISOStringToDatetime,
  withoutTime,
};
