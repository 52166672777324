// @flow

import type { Collection as CollectionType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Form } from 'semantic-ui-react';
import _ from 'underscore';
import i18n from '../i18n/I18n';
import AssociatedAuthorities from '../components/AssociatedAuthorities';
import AssociatedCollections from '../components/AssociatedCollections';
import AssociatedMedia from '../components/AssociatedMedia';
import AssociatedPublications from '../components/AssociatedPublications';
import AssociatedSites from '../components/AssociatedSites';
import CollectionTypesList from '../components/CollectionTypesList';
import Collections from '../services/Collections';
import KeywordsList from '../components/KeywordsList';
import PrimaryImage from '../components/PrimaryImage';
import {
  onMultiAddChildren,
  onMultiAddParents,
  onMultiAddRelated
} from '../utils/RecordAssociation';
import RecordAssociations from '../services/RecordAssociations';
import RecordHeader from '../components/RecordHeader';
import RichTextArea from '../components/RichTextArea';
import SimpleEditPage from './SimpleEditPage';
import Templates from '../resources/collections/Templates.json';
import withAssociationType, {
  AssociationTypes,
  type AssociationTypeProps
} from '../hooks/withAssociationType';

type Props = AssociationTypeProps & Translateable & {
  ...EditContainerProps,
  item: CollectionType
};

const Tabs = {
  details: 'details',
  media: 'media',
  sites: 'sites',
  publications: 'publications',
  authorities: 'authorities',
  collections: 'collections'
};

const AssociationTypeDefaults = {
  icon: {
    [AssociationTypes.child]: 'th',
    [AssociationTypes.parent]: 'th',
    [AssociationTypes.related]: 'th'
  },
  text: {
    [AssociationTypes.child]: i18n.t('Collection.associationTypes.child'),
    [AssociationTypes.parent]: i18n.t('Collection.associationTypes.parent'),
    [AssociationTypes.related]: i18n.t('Collection.associationTypes.related')
  }
};

const Collection: AbstractComponent<any> = withTranslation()(withAssociationType(AssociationTypeDefaults)(
  (props: Props) => (
    <SimpleEditPage
      {...props}
      className='collection'
    >
      <SimpleEditPage.Header>
        <RecordHeader
          {...props}
          description={props.item.collection_type && props.item.collection_type.name}
          header={props.item.name}
          includeAwardWinner={false}
          includeHcp={false}
          meta={props.item.record_id}
          renderImage={() => (
            <PrimaryImage
              item={props.item}
            />
          )}
        />
      </SimpleEditPage.Header>
      <SimpleEditPage.Tab
        key={Tabs.details}
        name={props.t('Common.tabs.details')}
      >
        <Form.Input
          error={props.isError('name')}
          label={props.t('Collection.labels.name')}
          required={props.isRequired('name')}
          onChange={props.onTextInputChange.bind(this, 'name')}
          value={props.item.name || ''}
        />
        <Form.Input
          error={props.isError('display_name')}
          label={props.t('Collection.labels.displayName')}
          required={props.isRequired('display_name')}
          onChange={props.onTextInputChange.bind(this, 'display_name')}
          value={props.item.display_name || ''}
        />
        <Form.Input
          label={props.t('Collection.labels.categories')}
        >
          <CollectionTypesList
            items={props.item.collection_descriptions}
            onDelete={props.onDeleteChildAssociation.bind(this, 'collection_descriptions')}
            onSave={props.onMultiAddChildAssociations.bind(this, 'collection_descriptions')}
          />
        </Form.Input>
        <Form.Dropdown
          error={props.isError('template')}
          label={props.t('Collection.labels.template')}
          onChange={props.onTextInputChange.bind(this, 'template')}
          options={Templates}
          required={props.isRequired('template')}
          selection
          value={props.item.template || ''}
        />
        <Form.Input
          label={props.t('Collection.labels.keywords')}
        >
          <KeywordsList
            items={props.item.collections_keywords}
            onDelete={props.onDeleteChildAssociation.bind(this, 'collections_keywords')}
            onSave={props.onMultiAddChildAssociations.bind(this, 'collections_keywords')}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('description')}
          label={props.t('Collection.labels.description')}
          required={props.isRequired('description')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('description', null, { value })}
            value={props.item.description || ''}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('caption')}
          label={props.t('Collection.labels.caption')}
          required={props.isRequired('caption')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('caption', null, { value })}
            value={props.item.caption || ''}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('abstract')}
          label={props.t('Collection.labels.abstract')}
          required={props.isRequired('abstract')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('abstract', null, { value })}
            value={props.item.abstract || ''}
          />
        </Form.Input>
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.media}
        name={props.t('Common.tabs.media')}
        count={props.item.associated_child_media_count}
      >
        <AssociatedMedia
          items={props.item.media_record_associations}
          modal={{
            props: {
              tabs: ['authorities', 'sites', 'donations']
            }
          }}
          onDataLoaded={(items) => props.onUpdateState({
            media_record_associations: [
              ...props.item.media_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'media_record_associations')}
          onEdit={(item, media) => props.onSaveChildAssociation('media_record_associations', {
            ...item,
            child: media
          })}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            parent_id: props.item.id,
            parent_type: 'Collection',
            child_type: 'MediaContent'
          })}
          onSave={(media) => {
            _.each(media, (m) => {
              props.onSaveChildAssociation('media_record_associations', {
                uid: uuid(),
                child_id: m.id,
                child_type: 'MediaContent',
                child: m
              });
            });
          }}
          onSaveMultiple={onMultiAddChildren.bind(this, props, 'media_record_associations', 'MediaContent')}
          onSelectPrimary={(item) => props.onSetState({
            media_record_associations: _.map(
              props.item.media_record_associations,
              (i) => ({ ...i, primary: i === item })
            )
          })}
          onUpdate={(items) => props.onSetState({ media_record_associations: items })}
          resolveMedia={(item) => item.child}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.sites}
        name={props.t('Common.tabs.sites')}
        count={props.item.associated_child_sites_count}
      >
        <AssociatedSites
          items={props.item.site_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            site_record_associations: [
              ...props.item.site_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'site_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            parent_id: props.item.id,
            parent_type: 'Collection',
            child_type: 'Site'
          })}
          onSave={onMultiAddChildren.bind(this, props, 'site_record_associations', 'Site')}
          onUpdate={(items) => props.onSetState({ site_record_associations: items })}
          resolveSite={(item) => item.child}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.publications}
        name={props.t('Common.tabs.publications')}
        count={props.item.associated_child_publications_count}
      >
        <AssociatedPublications
          items={props.item.publication_record_associations}
          modal={{
            props: {
              tabs: ['authorities', 'sites', 'donations']
            }
          }}
          onDataLoaded={(items) => props.onUpdateState({
            publication_record_associations: [
              ...props.item.publication_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'publication_record_associations')}
          onEdit={(item, publication) => props.onSaveChildAssociation('publication_record_associations', {
            ...item,
            child: publication
          })}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            parent_id: props.item.id,
            parent_type: 'Collection',
            child_type: 'Publication'
          })}
          onSave={(publications) => {
            _.each(publications, (p) => {
              props.onSaveChildAssociation('publication_record_associations', {
                uid: uuid(),
                child_id: p.id,
                child_type: 'Publication',
                child: p
              });
            });
          }}
          onSaveMultiple={onMultiAddChildren.bind(this, props, 'publication_record_associations', 'Publication')}
          onSelectPrimary={(item) => props.onSetState({
            publication_record_associations: _.map(
              props.item.publication_record_associations,
              (i) => ({ ...i, primary: i === item })
            )
          })}
          onUpdate={(items) => props.onSetState({ publication_record_associations: items })}
          resolvePublication={(item) => item.child}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.authorities}
        name={props.t('Common.tabs.authorities')}
        count={props.item.associated_child_authorities_count}
      >
        <AssociatedAuthorities
          items={props.item.authority_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            authority_record_associations: [
              ...props.item.authority_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'authority_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            parent_id: props.item.id,
            parent_type: 'Collection',
            child_type: 'Authority'
          })}
          onRoleSelection={props.onSaveChildAssociation.bind(this, 'authority_record_associations')}
          onSave={onMultiAddChildren.bind(this, props, 'authority_record_associations', 'Authority')}
          onUpdate={(items) => props.onSetState({ authority_record_associations: items })}
          resolveAuthority={(item) => item.child}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.collections}
        name={props.t('Common.tabs.collections')}
        count={props.item.associated_child_collections_count
          + props.item.associated_parent_collections_count
          + props.item.related_parent_collections_count
          + props.item.related_child_collections_count}
      >
        {props.associationType === AssociationTypes.child && (
          <AssociatedCollections
            addButton={props.t('Collection.buttons.addChildCollection')}
            buttons={[{
              render: props.renderAssociationTypeDropdown.bind(this)
            }]}
            items={props.item.child_collection_record_associations}
            onDataLoaded={(items) => props.onUpdateState({
              child_collection_record_associations: [
                ...props.item.child_collection_record_associations || [],
                ...items
              ]
            })}
            onDelete={props.onDeleteChildAssociation.bind(this, 'child_collection_record_associations')}
            onLoad={(params) => RecordAssociations.fetchAll({
              ...params,
              parent_id: props.item.id,
              parent_type: 'Collection',
              child_type: 'Collection'
            })}
            onSave={onMultiAddChildren.bind(this, props, 'child_collection_record_associations', 'Collection')}
            onUpdate={(items) => props.onSetState({ child_collection_record_associations: items })}
            resolveCollection={(item) => item.child}
          />
        )}
        {props.associationType === AssociationTypes.parent && (
          <AssociatedCollections
            buttons={[{
              render: props.renderAssociationTypeDropdown.bind(this)
            }]}
            items={props.item.parent_collection_record_associations}
            onDataLoaded={(items) => props.onUpdateState({
              parent_collection_record_associations: [
                ...props.item.parent_collection_record_associations || [],
                ...items
              ]
            })}
            onDelete={props.onDeleteChildAssociation.bind(this, 'parent_collection_record_associations')}
            onLoad={(params) => RecordAssociations.fetchAll({
              ...params,
              child_id: props.item.id,
              child_type: 'Collection',
              parent_type: 'Collection'
            })}
            onSave={onMultiAddParents.bind(this, props, 'parent_collection_record_associations', 'Collection')}
            onUpdate={(items) => props.onSetState({ parent_collection_record_associations: items })}
            resolveCollection={(item) => item.parent}
          />
        )}
        {props.associationType === AssociationTypes.related && (
          <AssociatedCollections
            addButton={props.t('Collection.buttons.addChildCollection')}
            buttons={[{
              render: props.renderAssociationTypeDropdown.bind(this)
            }]}
            items={props.item.related_collections}
            onDelete={props.onDeleteChildAssociation.bind(this, 'related_collections')}
            onSave={onMultiAddRelated.bind(this, props, 'related_collections', 'child_collection_id')}
            onUpdate={(items) => props.onSetState({ related_collections: items })}
            resolveCollection={(item) => item.child}
          />
        )}
      </SimpleEditPage.Tab>
    </SimpleEditPage>
  )
));

export default {
  component: Collection,
  onInitialize: (id: number): Promise<any> => (
    Collections
      .fetchOne(id)
      .then(({ data }) => data.collection)
  ),
  onSave: (collection: CollectionType): Promise<any> => (
    Collections
      .save(collection)
      .then(({ data }) => data.collection)
  )
};
