// @flow

import _ from 'underscore';
import Attachments from './Attachments';
import FormDataTransform from './FormDataTransform';
import HomePageItems from './HomePageItems';

import type { HomePage as HomePageType } from '@archnet/shared';

/**
 * Class for handling home page records.
 */
class HomePage extends FormDataTransform {
  /**
   * Returns the home_page parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'home_page';
  }

  /**
   * Returns the home page payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'title',
      'subtitle',
      'body',
      'start_date',
      'url_label',
      'url',
      'features_label',
      'image_position'
    ];
  }

  /**
   * Returns the passed home page as form data for POST/PUT requests.
   *
   * @param homePage
   *
   * @returns {FormData}
   */

  toPayload(homePage: HomePageType): FormData {
    const formData = super.toPayload(homePage);
    Attachments.appendFormData(formData, this.getParameterName(), homePage, 'content');
    HomePageItems.appendFormData(formData, this.getParameterName(), homePage, 'home_page_items');
    return formData;
  }
}

export default (new HomePage(): HomePage);
