// @flow

import { SiteUtils, type Site as SiteType, type Translateable } from '@archnet/shared';
import { BooleanIcon, EmbeddedList } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import {
  Divider,
  Form,
  Header,
  Icon,
  Popup,
  Button,
  Segment
} from 'semantic-ui-react';
import _ from 'underscore';
import i18n from '../i18n/I18n';
import AssociatedAuthorities from '../components/AssociatedAuthorities';
import AssociatedCollections from '../components/AssociatedCollections';
import AssociatedMedia from '../components/AssociatedMedia';
import AssociatedNameModal from '../components/AssociatedNameModal';
import AssociatedPublications from '../components/AssociatedPublications';
import AssociatedSites from '../components/AssociatedSites';
import BuildingUsagesList from '../components/BuildingUsagesList';
import EmptyTableRow from '../components/EmptyTableRow';
import EventItemModal from '../components/EventItemModal';
import Geography from '../components/Geography';
import KeywordsList from '../components/KeywordsList';
import MaterialsList from '../components/MaterialsList';
import OverallConditions from '../resources/sites/OverallConditions.json';
import PrimaryImage from '../components/PrimaryImage';
import QuillEditor from '../components/QuillEditor';
import {
  onMultiAddChildren,
  onMultiAddParents,
  onMultiAddRelated
} from '../utils/RecordAssociation';
import RecordAssociations from '../services/RecordAssociations';
import RecordHeader from '../components/RecordHeader';
import SimpleEditPage from './SimpleEditPage';
import SiteNameModal from '../components/SiteNameModal';
import Sites from '../services/Sites';
import StylePeriodsList from '../components/StylePeriodsList';
import withAssociationType, { AssociationTypes, type AssociationTypeProps } from '../hooks/withAssociationType';
import './Site.css';

type Props = AssociationTypeProps & Translateable & {
  ...EditContainerProps,
  item: SiteType
};

const AssociationTypeDefaults = {
  icon: {
    [AssociationTypes.child]: 'building',
    [AssociationTypes.parent]: 'building',
    [AssociationTypes.related]: 'building outline'
  },
  text: {
    [AssociationTypes.child]: i18n.t('Site.associationTypes.child'),
    [AssociationTypes.parent]: i18n.t('Site.associationTypes.parent'),
    [AssociationTypes.related]: i18n.t('Site.associationTypes.related')
  }
};

const ERROR_MULTIPLE_PRIMARY_NAMES = 'cannot have multiple primary names';
const ERROR_NO_PRIMARY_NAME = 'must have at least one primary name';

const Tabs = {
  details: 'details',
  geography: 'geography',
  media: 'media',
  publications: 'publications',
  collections: 'collections',
  authorities: 'authorities',
  sites: 'sites',
  technical: 'technical'
};

const Site: AbstractComponent<any> = withTranslation()(withAssociationType(AssociationTypeDefaults)((props: Props) => (
  <SimpleEditPage
    {...props}
    className='site'
  >
    <SimpleEditPage.Header>
      <RecordHeader
        {...props}
        description={props.item.record_id}
        header={SiteUtils.getPrimaryName(props.item)}
        meta={SiteUtils.formatLocation(props.item)}
        renderImage={() => (
          <PrimaryImage
            item={props.item}
          />
        )}
      />
    </SimpleEditPage.Header>
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <Segment>
        <Header
          content={props.t('Site.title.siteNames')}
        />
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'copy'
          }, {
            name: 'delete'
          }]}
          columns={[{
            className: 'drag-drop-icon',
            name: 'move',
            label: '',
            render: () => <Icon name='bars' />,
            sortable: false
          }, {
            name: 'qualify',
            label: props.t('Site.labels.qualify'),
            resolve: (sn) => sn.name_type && sn.name_type.name
          }, {
            name: 'name',
            label: props.t('Site.labels.name')
          }, {
            name: 'primary',
            label: props.t('Site.labels.primary'),
            render: (sn) => <BooleanIcon value={sn.primary} />
          }]}
          items={props.item.site_names}
          modal={{
            component: SiteNameModal
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'site_names')}
          onDrag={(dragIndex, hoverIndex) => {
            const items = [...props.item.site_names];
            const item = items[dragIndex];

            items.splice(dragIndex, 1);
            items.splice(hoverIndex, 0, item);

            props.onSetState({ site_names: items });
          }}
          onSave={props.onSaveChildAssociation.bind(this, 'site_names')}
          renderEmptyRow={() => <EmptyTableRow />}
        />
      </Segment>
      <Segment>
        <Header
          content={props.t('Site.labels.associatedNames')}
        />
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'copy'
          }, {
            name: 'delete'
          }]}
          columns={[{
            className: 'drag-drop-icon',
            name: 'move',
            label: '',
            render: () => <Icon name='bars' />,
            sortable: false
          }, {
            name: 'agent_id',
            label: props.t('Site.labels.agentId'),
            resolve: (ara) => ara.parent && ara.parent.record_id
          }, {
            name: 'agent_name',
            label: props.t('Site.labels.agentName'),
            resolve: (ara) => ara.parent && ara.parent.name
          }, {
            name: 'agent_role',
            label: props.t('Site.labels.agentRole'),
            resolve: (ara) => ara.role && ara.role.name
          }]}
          items={_.where(props.item.authority_record_associations)}
          modal={{
            component: AssociatedNameModal,
            props: {
              defaults: {
                parent_type: 'Authority'
              }
            }
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'authority_record_associations')}
          onDrag={(dragIndex, hoverIndex) => {
            const items = _.where(props.item.authority_record_associations);
            const item = items[dragIndex];

            items.splice(dragIndex, 1);
            items.splice(hoverIndex, 0, item);

            props.onSetState({ authority_record_associations: items });
          }}
          onSave={props.onSaveChildAssociation.bind(this, 'authority_record_associations')}
          renderEmptyRow={() => <EmptyTableRow />}
        />
      </Segment>
      <Segment>
        <Header
          content={props.t('Site.labels.description')}
        />
        <QuillEditor
          onChange={(text) => props.onTextInputChange('description', null, { value: text })}
          value={props.item.description || ''}
        />
      </Segment>
      <Segment>
        <Header
          content={props.t('Site.title.stylePeriods')}
        />
        <StylePeriodsList
          items={props.item.sites_style_periods}
          onDelete={props.onDeleteChildAssociation.bind(this, 'sites_style_periods')}
          onDrag={(items) => props.onSetState({ sites_style_periods: items })}
          onSave={props.onSaveChildAssociation.bind(this, 'sites_style_periods')}
        />
      </Segment>
      <Segment>
        <Header
          content={props.t('Site.title.events')}
        />
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'copy'
          }, {
            name: 'delete'
          }]}
          columns={[{
            className: 'drag-drop-icon',
            name: 'move',
            label: '',
            render: () => <Icon name='bars' />,
            sortable: false
          }, {
            name: 'event_type',
            label: props.t('Site.labels.eventType'),
            resolve: (e) => (e.event_type ? e.event_type.title : '')
          }, {
            name: 'start_year',
            label: props.t('Site.labels.startYear')
          }, {
            name: 'end_year',
            label: props.t('Site.labels.endYear')
          }, {
            name: 'description',
            label: props.t('Site.labels.description')
          }]}
          items={props.item.event_items}
          modal={{
            component: EventItemModal
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'event_items')}
          onDrag={(dragIndex, hoverIndex) => {
            const items = props.item.event_items;
            const item = items[dragIndex];

            items.splice(dragIndex, 1);
            items.splice(hoverIndex, 0, item);

            props.onSetState({ event_items: items });
          }}
          onSave={props.onSaveChildAssociation.bind(this, 'event_items')}
          renderEmptyRow={() => <EmptyTableRow />}
        />
      </Segment>
      <Segment>
        <Header
          content={props.t('Site.title.dimensions')}
        />
        <Segment>
          <div className='dimensions-field '>
            <Form.Input
              type='text'
              className='dimensions-form'
              value={props.item.dimensions || ''}
              placeholder={props.t('Site.messages.dimensionsPlaceholder')}
              onChange={props.onTextInputChange.bind(this, 'dimensions')}
            />
            <Popup
              trigger={
                <Button className='dimensions-button' color='blue' icon='superscript' />
              }
              content={(
                <div className='dimensions-button-row'>
                  <Button
                    color='green'
                    content='2'
                    size='mini'
                    onClick={(e) => props.onTextInputChange('dimensions', e, { value: `${props.item.dimensions}\u00B2` })}
                  />
                  <Button
                    color='green'
                    content='3'
                    size='mini'
                    onClick={(e) => props.onTextInputChange('dimensions', e, { value: `${props.item.dimensions}\u00B3` })}
                  />
                </div>
              )}
              on='click'
              position='top right'
            />
          </div>

        </Segment>
      </Segment>
      <Segment>
        <Header
          content={props.t('Site.title.materialsTechniques')}
        />
        <MaterialsList
          items={props.item.materials_sites}
          onDelete={props.onDeleteChildAssociation.bind(this, 'materials_sites')}
          onSave={props.onMultiAddChildAssociations.bind(this, 'materials_sites')}
        />
      </Segment>
      <Segment>
        <Header
          content={props.t('Site.title.buildingTypeUsage')}
        />
        <BuildingUsagesList
          items={props.item.building_usages_sites}
          onDelete={props.onDeleteChildAssociation.bind(this, 'building_usages_sites')}
          onSave={props.onMultiAddChildAssociations.bind(this, 'building_usages_sites')}
        />
      </Segment>
      <Segment>
        <Header
          content={props.t('Site.title.keywords')}
        />
        <KeywordsList
          items={props.item.keywords_sites}
          onDelete={props.onDeleteChildAssociation.bind(this, 'keywords_sites')}
          onSave={props.onMultiAddChildAssociations.bind(this, 'keywords_sites')}
        />
      </Segment>
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.geography}
      name={props.t('Common.tabs.geography')}
    >
      <Geography
        {...props}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.media}
      name={props.t('Common.tabs.media')}
      count={props.item.associated_child_media_count}
    >
      <AssociatedMedia
        items={props.item.media_record_associations}
        modal={{
          props: {
            tabs: ['authorities', 'collections', 'donations']
          }
        }}
        onDataLoaded={(items) => props.onUpdateState({
          media_record_associations: [
            ...props.item.media_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'media_record_associations')}
        onEdit={(item, media) => props.onSaveChildAssociation('media_record_associations', {
          ...item,
          child: media
        })}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          parent_id: props.item.id,
          parent_type: 'Site',
          child_type: 'MediaContent'
        })}
        onSave={(media) => {
          _.each(media, (m) => {
            props.onSaveChildAssociation('media_record_associations', {
              uid: uuid(),
              child_id: m.id,
              child_type: 'MediaContent',
              child: m
            });
          });
        }}
        onSaveMultiple={onMultiAddChildren.bind(this, props, 'media_record_associations', 'MediaContent')}
        onSelectPrimary={(item) => props.onSetState({
          media_record_associations: _.map(
            props.item.media_record_associations,
            (i) => ({ ...i, primary: i === item })
          )
        })}
        onUpdate={(items) => props.onSetState({ media_record_associations: items })}
        resolveMedia={(item) => item.child}
        tabs={['authorities', 'collections', 'donations']}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.publications}
      name={props.t('Common.tabs.publications')}
      count={props.item.associated_child_publications_count}
    >
      <AssociatedPublications
        items={props.item.publication_record_associations}
        modal={{
          props: {
            tabs: ['authorities', 'collections', 'donations']
          }
        }}
        onDataLoaded={(items) => props.onUpdateState({
          publication_record_associations: [
            ...props.item.publication_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'publication_record_associations')}
        onEdit={(item, publication) => props.onSaveChildAssociation('publication_record_associations', {
          ...item,
          child: publication
        })}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          parent_id: props.item.id,
          parent_type: 'Site',
          child_type: 'Publication'
        })}
        onSave={(publications) => {
          _.each(publications, (p) => {
            props.onSaveChildAssociation('publication_record_associations', {
              uid: uuid(),
              child_id: p.id,
              child_type: 'Publication',
              child: p
            });
          });
        }}
        onSaveMultiple={onMultiAddChildren.bind(this, props, 'publication_record_associations', 'Publication')}
        onSelectPrimary={(item) => props.onSetState({
          publication_record_associations: _.map(
            props.item.publication_record_associations,
            (i) => ({ ...i, primary: i === item })
          )
        })}
        onUpdate={(items) => props.onSetState({ publication_record_associations: items })}
        resolvePublication={(item) => item.child}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.collections}
      name={props.t('Common.tabs.collections')}
      count={props.item.associated_parent_collections_count}
    >
      <AssociatedCollections
        items={props.item.collection_record_associations}
        onDataLoaded={(items) => props.onUpdateState({
          collection_record_associations: [
            ...props.item.collection_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'collection_record_associations')}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          child_id: props.item.id,
          child_type: 'Site',
          parent_type: 'Collection'
        })}
        onSave={onMultiAddParents.bind(this, props, 'collection_record_associations', 'Collection')}
        onUpdate={(items) => props.onSetState({ collection_record_associations: items })}
        resolveCollection={(item) => item.parent}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.authorities}
      name={props.t('Common.tabs.authorities')}
      count={props.item.associated_parent_authorities_count}
    >
      <AssociatedAuthorities
        items={props.item.authority_record_associations}
        onDataLoaded={(items) => props.onUpdateState({
          authority_record_associations: [
            ...props.item.authority_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'authority_record_associations')}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          child_id: props.item.id,
          child_type: 'Site',
          parent_type: 'Authority'
        })}
        onRoleSelection={props.onSaveChildAssociation.bind(this, 'authority_record_associations')}
        onSave={onMultiAddParents.bind(this, props, 'authority_record_associations', 'Authority')}
        onUpdate={(items) => props.onSetState({ authority_record_associations: items })}
        resolveAuthority={(item) => item.parent}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.sites}
      name={props.t('Common.tabs.sites')}
      count={props.item.related_child_sites_count}
    >
      {props.associationType === AssociationTypes.child && (
        <AssociatedSites
          addButton={props.t('Site.buttons.addChildSites')}
          buttons={[{
            render: props.renderAssociationTypeDropdown.bind(this)
          }]}
          items={props.item.child_site_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            child_site_record_associations: [
              ...props.item.child_site_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'child_site_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            parent_id: props.item.id,
            parent_type: 'Site',
            child_type: 'Site'
          })}
          onSave={onMultiAddChildren.bind(this, props, 'child_site_record_associations', 'Site')}
          onUpdate={(items) => props.onSetState({ child_site_record_associations: items })}
          resolveSite={(item) => item.child}
        />
      )}
      {props.associationType === AssociationTypes.parent && (
        <AssociatedSites
          buttons={[{
            render: props.renderAssociationTypeDropdown.bind(this)
          }]}
          items={props.item.parent_site_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            parent_site_record_associations: [
              ...props.item.parent_site_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'parent_site_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            child_id: props.item.id,
            child_type: 'Site',
            parent_type: 'Site'
          })}
          onSave={onMultiAddParents.bind(this, props, 'parent_site_record_associations', 'Site')}
          onUpdate={(items) => props.onSetState({ parent_site_record_associations: items })}
          resolveSite={(item) => item.parent}
        />
      )}
      {props.associationType === AssociationTypes.related && (
        <AssociatedSites
          addButton={props.t('Site.buttons.addRelatedSites')}
          buttons={[{
            render: props.renderAssociationTypeDropdown.bind(this)
          }]}
          items={props.item.related_sites}
          onDelete={props.onDeleteChildAssociation.bind(this, 'related_sites')}
          onSave={onMultiAddRelated.bind(this, props, 'related_sites', 'child_site_id')}
          onUpdate={(items) => props.onSetState({ related_sites: items })}
          resolveSite={(item) => item.child}
        />
      )}
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.technical}
      name={props.t('Site.tabs.technical')}
    >
      <Form.Dropdown
        autoFocus
        error={props.isError('overall_condition')}
        fluid
        label={props.t('Site.labels.overallCondition')}
        onChange={props.onTextInputChange.bind(this, 'overall_condition')}
        options={OverallConditions}
        required={props.isRequired('overall_condition')}
        selectOnBlur={false}
        selection
        value={props.item.overall_condition || ''}
      />
      <Divider
        section
      />
      <Form.Input
        error={props.isError('structural_stability')}
        label={props.t('Site.labels.structuralStability')}
        onChange={props.onTextInputChange.bind(this, 'structural_stability')}
        required={props.isRequired('structural_stability')}
        value={props.item.structural_stability || ''}
      />
      <Form.Input
        error={props.isError('architectural_integrity')}
        label={props.t('Site.labels.architecturalIntegrity')}
        onChange={props.onTextInputChange.bind(this, 'architectural_integrity')}
        required={props.isRequired('architectural_integrity')}
        value={props.item.architectural_integrity || ''}
      />
      <Form.Input
        error={props.isError('damage_assessment')}
        label={props.t('Site.labels.damageAssessment')}
        onChange={props.onTextInputChange.bind(this, 'damage_assessment')}
        required={props.isRequired('damage_assessment')}
        value={props.item.damage_assessment || ''}
      />
      <Divider
        section
      />
      <Form.Input
        error={props.isError('cultural_risk')}
        label={props.t('Site.labels.culturalRisk')}
        onChange={props.onTextInputChange.bind(this, 'cultural_risk')}
        required={props.isRequired('cultural_risk')}
        value={props.item.cultural_risk || ''}
      />
      <Form.Input
        error={props.isError('political_risk')}
        label={props.t('Site.labels.politicalRisk')}
        onChange={props.onTextInputChange.bind(this, 'political_risk')}
        required={props.isRequired('political_risk')}
        value={props.item.political_risk || ''}
      />
      <Form.Input
        error={props.isError('economic_risk')}
        label={props.t('Site.labels.economicRisk')}
        onChange={props.onTextInputChange.bind(this, 'economic_risk')}
        required={props.isRequired('economic_risk')}
        value={props.item.economic_risk || ''}
      />
      <Form.Input
        error={props.isError('social_risk')}
        label={props.t('Site.labels.socialRisk')}
        onChange={props.onTextInputChange.bind(this, 'social_risk')}
        required={props.isRequired('social_risk')}
        value={props.item.social_risk || ''}
      />
      <Form.Input
        error={props.isError('environmental_risk')}
        label={props.t('Site.labels.environmentalRisk')}
        onChange={props.onTextInputChange.bind(this, 'environmental_risk')}
        required={props.isRequired('environmental_risk')}
        value={props.item.environmental_risk || ''}
      />
      <Divider
        section
      />
      <Form.Input
        error={props.isError('feasibility')}
        label={props.t('Site.labels.feasibility')}
        onChange={props.onTextInputChange.bind(this, 'feasibility')}
        required={props.isRequired('feasibility')}
        value={props.item.feasibility || ''}
      />
      <Form.Input
        error={props.isError('viability')}
        label={props.t('Site.labels.viability')}
        onChange={props.onTextInputChange.bind(this, 'viability')}
        required={props.isRequired('viability')}
        value={props.item.viability || ''}
      />
      <Form.Input
        error={props.isError('conservation_status')}
        label={props.t('Site.labels.conservationStatus')}
        onChange={props.onTextInputChange.bind(this, 'conservation_status')}
        required={props.isRequired('conservation_status')}
        value={props.item.conservation_status || ''}
      />
      <Divider
        section
      />
      <Form.Input
        error={props.isError('occupancy')}
        label={props.t('Site.labels.occupancy')}
        onChange={props.onTextInputChange.bind(this, 'occupancy')}
        required={props.isRequired('occupancy')}
        value={props.item.occupancy || ''}
      />
      <Form.Input
        error={props.isError('proposed_intervention')}
        label={props.t('Site.labels.proposedIntervention')}
        onChange={props.onTextInputChange.bind(this, 'proposed_intervention')}
        required={props.isRequired('proposed_intervention')}
        value={props.item.proposed_intervention || ''}
      />
      <Form.Input
        error={props.isError('priority')}
        label={props.t('Site.labels.priority')}
        onChange={props.onTextInputChange.bind(this, 'priority')}
        required={props.isRequired('priority')}
        value={props.item.priority || ''}
      />
    </SimpleEditPage.Tab>
  </SimpleEditPage>
)));

export default {
  component: Site,
  onInitialize: (id: number): Promise<any> => (
    Sites
      .fetchOne(id)
      .then(({ data }) => data.site)
  ),
  onSave: (site: SiteType): Promise<any> => (
    Sites
      .save(site)
      .then(({ data }) => data.site)
  ),
  resolveValidationError: ({ error }: { error: string }): any => {
    const errors: any = {};

    // Sites cannot have more than one primary name
    if (error.includes(ERROR_MULTIPLE_PRIMARY_NAMES)) {
      errors.site = i18n.t('Site.errors.multiplePrimaryNames', { error });
    } else if (error.includes(ERROR_NO_PRIMARY_NAME)) {
      errors.site = i18n.t('Site.errors.noPrimaryName', { error });
    }

    return errors;
  }
};
