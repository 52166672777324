// @flow

import type { SiteName, Translateable } from '@archnet/shared';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';

import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import NameTypes from '../services/NameTypes';
import NameType from '../transforms/NameType';

type Props = Translateable & {
  ...EditContainerProps,
  item: SiteName
};

const SiteNameModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='site-name-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('SiteNameModal.title.edit')
        : props.t('SiteNameModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('name_type_id')}
        label={props.t('SiteNameModal.labels.qualify')}
        required={props.isRequired('name_type_id')}
      >
        <AssociatedDropdown
          collectionName='name_types'
          onSearch={(search) => NameTypes.fetchAll({ per_page: 0, search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'name_type_id', 'name_type')}
          renderOption={(nameType) => NameType.toDropdown(nameType)}
          searchQuery={props.item.name_type && props.item.name_type.name}
          value={props.item.name_type_id}
        />
      </Form.Input>
      <Form.Input
        autoFocus
        error={props.isError('name')}
        label={props.t('SiteNameModal.labels.name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        required={props.isRequired('name')}
        value={props.item.name || ''}
      />
      <Form.Checkbox
        checked={props.item.primary}
        label={props.t('SiteNameModal.labels.primary')}
        onChange={props.onCheckboxInputChange.bind(this, 'primary')}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default SiteNameModal;
