// @flow

import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './DisplayDocument.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DisplayDocument = () => {
  const query = useQuery();
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);

  const current = `${page}`;

  const handlePDFLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <div className='button-bar'>
        <div className='ui buttons'>
          <button className='ui button page-button' disabled={page === 1} onClick={() => setPage(page - 1)}>
            previous
          </button>
          <div className='or' data-text={current} />
          <button
            className='ui positive button page-button'
            disabled={page === numPages}
            onClick={() => setPage(page + 1)}
          >
            next
          </button>
        </div>
      </div>
      <Document
        file={query.get('source')}
        onLoadError={(e) => console.log(e.message)}
        onLoadSuccess={handlePDFLoadSuccess}
      >
        <Page
          pageNumber={page}
          renderAnnotationLayer={false}
        />
      </Document>
    </div>
  );
};

export default DisplayDocument;
