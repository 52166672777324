// @flow

import BaseService from './BaseService';
import NameType from '../transforms/NameType';

/**
 * Service class for all name type based API calls.
 */
class NameTypes extends BaseService {
  /**
   * Returns the name type base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/name_types';
  }

  /**
   * Returns the name types transform.
   *
   * @returns {NameType}
   */
  getTransform(): typeof NameType {
    return NameType;
  }
}

export default (new NameTypes(): NameTypes);
