// @flow

import { MediaUtils, PlayButton, type MediaContent } from '@archnet/shared';
import { LazyImage, LazyVideo } from '@performant-software/semantic-components';
import i18n from '../i18n/I18n';
import React, { useMemo, type Node } from 'react';
import './LazyMedia.css';

type Props = {
  children?: Node,
  media: MediaContent
};

const LazyMedia = ({ media, children, ...props }: Props): Node => {
  const viewButtonLabel = useMemo(() => {
    if (media?.url_is_local) {
      return i18n.t('MediaContent.buttons.preview');
    }
    return null;
  }, [media?.url_is_local]);
  if (MediaUtils.isVideo(media) && MediaUtils.isExternal(media)) {
    const src = MediaUtils.getExternalUrl(media);
    const preview = MediaUtils.getPreviewUrl(media);

    return (
      <LazyVideo
        {...props}
        autoPlay
        embedded
        icon={(
          <div
            className='lazy-media placeholder icon'
          >
            <div>
              <PlayButton />
            </div>
          </div>
        )}
        key={src}
        preview={preview}
        src={src}
        playButtonLabel={viewButtonLabel}
      >
        { children }
      </LazyVideo>
    );
  }

  if (MediaUtils.isVideo(media)) {
    return (
      <LazyVideo
        {...props}
        key={media.content_url}
        preview={MediaUtils.getPreviewUrl(media)}
        src={media.content_url}
        playButtonLabel={viewButtonLabel}
      >
        { children }
      </LazyVideo>
    );
  }

  if (MediaUtils.isImage(media) || media?.content_url) {
    return (
      <LazyImage
        {...props}
        key={media.content_url}
        preview={MediaUtils.getPreviewUrl(media)}
        src={MediaUtils.getImageUrl(media)}
        viewButtonLabel={viewButtonLabel}
      >
        { children }
      </LazyImage>
    );
  }

  return (
    <LazyImage
      {...props}
      viewButtonLabel={viewButtonLabel}
    >
      { children }
    </LazyImage>
  );
};

LazyMedia.defaultProps = {
  dimmable: true
};

export default LazyMedia;
