// @flow

import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import Authorities from '../services/Authorities';
import Authority from '../transforms/Authority';
import AuthorityRole from '../transforms/AuthorityRole';
import AuthorityRoles from '../services/AuthorityRoles';
import AgentModal, { AgentNameTypes } from './AgentModal';
import AgentRoleModal from './AgentRoleModal';
import './AssociatedNameModal.css';

import type {
  AuthorityRole as AuthorityRoleType,
  Authority as AuthorityType,
  Site,
  Translateable
} from '@archnet/shared';

type Props = Translateable & {
  ...EditContainerProps,
  item: Site & {
    data: number,
    parent_id: number,
    parent: AuthorityType,
    role: AuthorityRoleType
  }
};

const AssociatedNameModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='associated-name-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('AssociatedNameModal.title.edit')
        : props.t('AssociatedNameModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('parent_id')}
        label={props.t('AssociatedNameModal.labels.agent')}
        required={props.isRequired('parent_id')}
      >
        <AssociatedDropdown
          collectionName='authorities'
          onSearch={(search) => Authorities.fetchAll({ search, sort_by: ['first_name', 'last_name'] })}
          onSelection={props.onAssociationInputChange.bind(this, 'parent_id', 'parent')}
          renderOption={(authority) => Authority.toDropdown(authority)}
          searchQuery={props.item.parent && props.item.parent.name}
          value={props.item.parent_id}
          modal={{
            component: AgentModal,
            props: {
              defaults: {
                parent: 'Authority',
                nameType: AgentNameTypes.individual
              }
            },
            onSave: (authority) => Authorities
              .save({ authority_names: [authority] })
              .then(({ data }) => data.authority)
          }}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('data')}
        label={props.t('AssociatedNameModal.labels.agentRole')}
        required={props.isRequired('data')}
      >
        <AssociatedDropdown
          collectionName='authority_roles'
          onSearch={(search) => AuthorityRoles.fetchAll({ per_page: 0, search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'data', 'role')}
          renderOption={(ar) => AuthorityRole.toDropdown(ar)}
          searchQuery={props.item.role && props.item.role.name}
          value={props.item.data}
          modal={{
            component: AgentRoleModal,
            onSave: (authorityRole) => AuthorityRoles.save(authorityRole).then(({ data }) => data.authority_role)
          }}
        />
      </Form.Input>
    </Modal.Content>
    { props.children }
  </Modal>
));

export default AssociatedNameModal;
