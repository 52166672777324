// @flow

import type { Translateable } from '@archnet/shared';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import AdminListTable from '../components/AdminListTable';
import Date from '../utils/Date';
import HomePagesService from '../services/HomePages';

const HomePages: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminListTable
    className='home-pages'
    collectionName='home_pages'
    columns={[{
      name: 'start_date',
      label: props.t('HomePages.columns.startDate'),
      resolve: (hp) => Date.formatDatetime(hp.start_date),
      sortable: true,
      sortDirection: 'descending'
    }, {
      name: 'title',
      label: props.t('HomePages.columns.title'),
      sortable: true
    }]}
    onDelete={(homePage) => HomePagesService.delete(homePage)}
    onLoad={(params) => HomePagesService.fetchAll(params)}
    route='home_pages'
  />
));

export default HomePages;
