// @flow

import type { Translateable } from '@archnet/shared';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import AdminListTable from '../components/AdminListTable';
import NameTypeService from '../services/NameTypes';

const NameTypes: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminListTable
    className='name-types'
    collectionName='name_types'
    searchable
    columns={[{
      name: 'name',
      label: props.t('NameTypes.columns.name'),
      sortable: true
    }]}
    onDelete={(nameType) => NameTypeService.delete(nameType)}
    onLoad={(params) => NameTypeService.search(params)}
    route='name_types'
  />
));

export default NameTypes;
