// @flow

import type { HomePage as HomePageType, HomePageItem, Translateable } from '@archnet/shared';
import {
  DatetimeLocalInput,
  DropdownButton,
  FileInputButton,
  ListFilters,
  Selectize
} from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { useCallback, useState, type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import {
  Form,
  Header,
  Item,
  Label
} from 'semantic-ui-react';
import _ from 'underscore';
import { ItemUtils, MediaUtils, TypeLabel } from '@archnet/shared';
import AssociatedRecordsList from '../components/AssociatedRecordsList';
import Authorities from '../services/Authorities';
import AuthorityFilters from '../filters/AuthorityFilters';
import CollectionFilters from '../filters/CollectionFilters';
import Collections from '../services/Collections';
import DateUtils from '../utils/Date';
import HomePages from '../services/HomePages';
import MediaContentFilters from '../filters/MediaContentFilters';
import MediaContents from '../services/MediaContents';
import PrimaryImage from '../components/PrimaryImage';
import PrimaryImageView from '../components/PrimaryImageView';
import PublicationFilters from '../filters/PublicationFilters';
import Publications from '../services/Publications';
import PublishedLabel from '../components/PublishedLabel';
import RichTextArea from '../components/RichTextArea';
import SelectizeHeader from '../components/SelectizeHeader';
import SimpleEditPage from './SimpleEditPage';
import SiteFilters from '../filters/SiteFilters';
import Sites from '../services/Sites';

type Props = EditContainerProps & Translateable & {
  item: HomePageType
};

const DropdownOptions = [{
  key: 'site',
  value: 'site',
  text: 'Site'
}, {
  key: 'authority',
  value: 'authority',
  text: 'Authority'
}, {
  key: 'collection',
  value: 'collection',
  text: 'Collection'
}, {
  key: 'publication',
  value: 'publication',
  text: 'Document'
}, {
  key: 'image',
  value: 'image',
  text: 'Image/Video'
}];

const Modals = {
  site: 'site',
  authority: 'authority',
  collection: 'collection',
  publication: 'publication',
  image: 'image'
};

const ImageProps = {
  dimmable: false,
  image: {
    style: {
      height: '75px',
      objectFit: 'cover',
      width: '75px'
    }
  },
  size: 'tiny'
};

const ItemTypes = {
  authority: 'Authority',
  collection: 'Collection',
  media: 'MediaContent',
  publication: 'Publication',
  site: 'Site'
};

const Tabs = {
  details: 'details',
  features: 'features'
};

const HomePage: AbstractComponent<any> = withTranslation()((props: Props) => {
  const [modal, setModal] = useState(null);

  /**
   * Returns the array of selected items for the passed type.
   *
   * @type {function(*): *}
   */
  const getSelectedItems = useCallback((type: string) => (
    _.chain(props.item.home_page_items)
      .where({ item_type: type })
      .map((hpi) => hpi.item)
      .value()
  ), [props.item.home_page_items]);

  /**
   * Adds the first child in the passed list to list of home page items, closes the modal, and returns a resolved
   * Promise, which is expected by the Selectize component.
   *
   * @param items
   * @param type
   *
   * @returns {*}
   */
  const onMultiAdd = useCallback((items: Array<HomePageItem>, type: string) => {
    // Create or find home page items
    const find = (item: HomePageItem) => _.findWhere(props.item.home_page_items, { item_id: item.id, item_type: type });

    const create = (item: HomePageItem) => ({
      uid: uuid(),
      item_id: item.id,
      item_type: type,
      item
    });

    props.onMultiAddChildAssociations('home_page_items', [
      ..._.map(items, (item) => find(item) || create(item)),
      ..._.filter(props.item.home_page_items, (hpi) => hpi.item_type !== type)
    ]);

    setModal(null);

    return Promise.resolve();
  }, [props.item.home_page_items, props.onMultiAddChildAssociations]);

  /**
   * Renders the header for the passed item.
   *
   * @param item
   * @param type
   *
   * @returns {JSX.Element}
   */
  const renderHeader = (item: any, type: string) => {
    const attributes = ItemUtils.getViewAttributes(item, type);

    return (
      <Header
        content={attributes.header}
      />
    );
  };

  /**
   * Renders the image for the passed item.
   *
   * @param item
   * @param type
   * @param image
   *
   * @returns {JSX.Element}
   */
  const renderImage = (item: any, type: string, image: any = {}) => (
    <PrimaryImageView
      {...image}
      item={item}
    />
  );

  /**
   * Renders the item for the Selectize component.
   *
   * @param item
   * @param type
   *
   * @returns {JSX.Element}
   */
  const renderItem = (item: any, type: string) => (
    <Item.Group>
      <Item>
        <Item.Image
          style={{
            width: 'unset'
          }}
        >
          { renderImage(item, type, ImageProps) }
        </Item.Image>
        <Item.Content>
          <Item.Header>
            { renderHeader(item, type) }
          </Item.Header>
          <Item.Meta>
            { renderMeta(item, type) }
          </Item.Meta>
        </Item.Content>
      </Item>
    </Item.Group>
  );

  /**
   * Gets an absolute url from the view attributes of an item.
   *
   * @param item
   *
   * @returns {string}
   */
  const getItemUrl = (item: any) => {
    const attributes = ItemUtils.getViewAttributes(item.item, item.item_type);
    const { url } = attributes;
    return new URL(url, document.baseURI).href;
  };

  /**
   * Renders the meta attribute for the passed item.
   *
   * @param item
   * @param type
   *
   * @returns {*}
   */
  const renderMeta = (item: any, type: string) => {
    const attributes = ItemUtils.getViewAttributes(item, type);
    return attributes.meta;
  };

  return (
    <SimpleEditPage
      {...props}
      className='home-page'
    >
      <SimpleEditPage.Header>
        <PrimaryImage
          download={props.item.content_download_url}
          item={props.item}
        >
          <FileInputButton
            color='orange'
            content={props.item.content_url
              ? props.t('Common.buttons.replaceFile')
              : props.t('HomePage.buttons.upload')}
            icon={props.item.content_url ? 'refresh' : 'cloud upload'}
            onSelection={(files) => props.onSetState(MediaUtils.getSelectionState(files))}
          />
        </PrimaryImage>
      </SimpleEditPage.Header>
      <SimpleEditPage.Tab
        key={Tabs.details}
        name={props.t('Common.tabs.details')}
      >
        <Form.Input
          error={props.isError('title')}
          label={props.t('HomePage.labels.title')}
          required={props.isRequired('title')}
          onChange={props.onTextInputChange.bind(this, 'title')}
          value={props.item.title || ''}
        />
        <Form.Input
          error={props.isError('subtitle')}
          label={props.t('HomePage.labels.subtitle')}
          required={props.isRequired('subtitle')}
          onChange={props.onTextInputChange.bind(this, 'subtitle')}
          value={props.item.subtitle || ''}
        />
        <Form.Input
          error={props.isError('body')}
          label={props.t('HomePage.labels.body')}
          required={props.isRequired('body')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('body', null, { value })}
            value={props.item.body || ''}
            formats={[...RichTextArea.defaultProps.formats, 'align']}
            modules={{
              ...RichTextArea.defaultProps.modules,
              toolbar: [...RichTextArea.defaultProps.modules.toolbar,
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
              ]
            }}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('features_label')}
          label={props.t('HomePage.labels.featuresLabel')}
          required={props.isRequired('features_label')}
          onChange={props.onTextInputChange.bind(this, 'features_label')}
          value={props.item.features_label || ''}
          placeholder={props.t('HomePage.messages.featuresLabel')}
        />
        <Form.Input
          error={props.isError('start_date')}
          label={props.t('HomePage.labels.startDate')}
          required={props.isRequired('start_date')}
        >
          <DatetimeLocalInput
            onChange={(startDate) => props.onSetState({
              start_date: DateUtils.datetimeToISOString(startDate)
            })}
            value={DateUtils.ISOStringToDatetime(props.item.start_date) || ''}
          />
        </Form.Input>
        <Form.Group inline>
          <label>{props.t('HomePage.labels.imagePosition.label')}</label>
          <Form.Radio
            label={props.t('HomePage.labels.imagePosition.top')}
            value='top'
            checked={props.item.image_position === 'top'}
            onChange={() => props.onTextInputChange('image_position', null, { value: 'top' })}
          />
          <Form.Radio
            label={props.t('HomePage.labels.imagePosition.center')}
            value='center'
            checked={props.item.image_position === 'center' || !props.item.image_position}
            onChange={() => props.onTextInputChange('image_position', null, { value: 'center' })}
          />
          <Form.Radio
            label={props.t('HomePage.labels.imagePosition.bottom')}
            value='bottom'
            checked={props.item.image_position === 'bottom'}
            onChange={() => props.onTextInputChange('image_position', null, { value: 'bottom' })}
          />
        </Form.Group>
        <Form.Input
          error={props.isError('url')}
          label={props.t('HomePage.labels.url')}
          required={props.isRequired('url')}
          onChange={props.onTextInputChange.bind(this, 'url')}
          value={props.item.url || ''}
          placeholder={props.t('HomePage.messages.url')}
        />
        <Form.Input
          error={props.isError('url_label')}
          label={props.t('HomePage.labels.urlLabel')}
          required={props.isRequired('url_label')}
          onChange={props.onTextInputChange.bind(this, 'url_label')}
          value={props.item.url_label || ''}
          placeholder={props.t('HomePage.messages.urlLabel')}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.features}
        name={props.t('HomePage.tabs.features')}
      >
        <AssociatedRecordsList
          actions={[{
            basic: true,
            color: 'red',
            icon: 'trash',
            label: props.t('Common.buttons.delete'),
            onClick: props.onDeleteChildAssociation.bind(this, 'home_page_items')
          }]}
          buttons={[{
            render: () => (
              <DropdownButton
                color='green'
                icon='plus'
                options={DropdownOptions}
                onChange={(e, { value }) => setModal(value)}
                text={props.t('Common.buttons.add')}
                value={modal}
              />
            )
          }]}
          items={_.filter(props.item.home_page_items, (item) => !item._destroy)}
          onUpdate={(items) => props.onSetState({ home_page_items: items })}
          renderExtra={(item) => (
            <>
              <Label.Group>
                <PublishedLabel
                  icon
                  published={item.item && item.item.published}
                />
                <TypeLabel
                  type={item.item_type}
                />
              </Label.Group>
              <Form.Input
                label={props.t('HomePage.labels.features.label')}
                onChange={(e) => props.onSaveChildAssociation('home_page_items', { ...item, label: e.target.value })}
                value={item.label}
                placeholder={ItemUtils.getViewAttributes(item.item, item.item_type).header}
              />
              <Form.Input
                label={props.t('HomePage.labels.features.subhead')}
                onChange={(e) => props.onSaveChildAssociation('home_page_items', { ...item, subhead: e.target.value })}
                value={item.subhead}
                placeholder={ItemUtils.getViewAttributes(item.item, item.item_type).meta}
              />
              <Form.Input
                label={props.t('HomePage.labels.features.url')}
                onChange={(e) => props.onSaveChildAssociation('home_page_items', { ...item, url: e.target.value })}
                value={item.url}
                placeholder={getItemUrl(item)}
              />
            </>
          )}
          renderHeader={(item) => renderHeader(item.item, item.item_type)}
          renderImage={(item) => renderImage(item.item, item.item_type)}
          renderMeta={(item) => renderMeta(item.item, item.item_type)}
        />
        { modal === Modals.site && (
          <Selectize
            collectionName='sites'
            filters={{
              component: ListFilters,
              props: {
                filters: SiteFilters
              }
            }}
            onClose={() => setModal(null)}
            onLoad={(params) => Sites.search({ ...params, sort_by: 'site_names.name', per_page: 5 })}
            onSave={(items) => onMultiAdd(items, ItemTypes.site)}
            renderHeader={(headerProps) => (
              <SelectizeHeader
                {...headerProps}
                type={ItemTypes.site}
              />
            )}
            renderItem={(item) => renderItem(item, ItemTypes.site)}
            selectedItems={getSelectedItems(ItemTypes.site)}
            title={props.t('HomePage.titles.site')}
          />
        )}
        { modal === Modals.authority && (
          <Selectize
            collectionName='authorities'
            filters={{
              component: ListFilters,
              props: {
                filters: AuthorityFilters
              }
            }}
            onClose={() => setModal(null)}
            onLoad={(params) => Authorities.search({ ...params, sort_by: ['first_name', 'last_name'], per_page: 5 })}
            onSave={(items) => onMultiAdd(items, ItemTypes.authority)}
            renderHeader={(headerProps) => (
              <SelectizeHeader
                {...headerProps}
                type={ItemTypes.authority}
              />
            )}
            renderItem={(item) => renderItem(item, ItemTypes.authority)}
            selectedItems={getSelectedItems(ItemTypes.authority)}
            title={props.t('HomePage.titles.authority')}
          />
        )}
        { modal === Modals.collection && (
          <Selectize
            collectionName='collections'
            filters={{
              component: ListFilters,
              props: {
                filters: CollectionFilters
              }
            }}
            onClose={() => setModal(null)}
            onLoad={(params) => Collections.search({ ...params, sort_by: 'name', per_page: 5 })}
            onSave={(items) => onMultiAdd(items, ItemTypes.collection)}
            renderHeader={(headerProps) => (
              <SelectizeHeader
                {...headerProps}
                type={ItemTypes.collection}
              />
            )}
            renderItem={(item) => renderItem(item, ItemTypes.collection)}
            selectedItems={getSelectedItems(ItemTypes.collection)}
            title={props.t('HomePage.titles.collection')}
          />
        )}
        { modal === Modals.publication && (
          <Selectize
            collectionName='publications'
            filters={{
              component: ListFilters,
              props: {
                filters: PublicationFilters
              }
            }}
            onClose={() => setModal(null)}
            onLoad={(params) => Publications.search({ ...params, sort_by: 'name', per_page: 5 })}
            onSave={(items) => onMultiAdd(items, ItemTypes.publication)}
            renderHeader={(headerProps) => (
              <SelectizeHeader
                {...headerProps}
                type={ItemTypes.publication}
              />
            )}
            renderItem={(item) => renderItem(item, ItemTypes.publication)}
            selectedItems={getSelectedItems(ItemTypes.publication)}
            title={props.t('HomePage.titles.publication')}
          />
        )}
        { modal === Modals.image && (
          <Selectize
            collectionName='media_contents'
            filters={{
              component: ListFilters,
              props: {
                filters: MediaContentFilters
              }
            }}
            onClose={() => setModal(null)}
            onLoad={(params) => MediaContents.search({ ...params, sort_by: 'name', per_page: 5 })}
            onSave={(items) => onMultiAdd(items, ItemTypes.media)}
            renderHeader={(headerProps) => (
              <SelectizeHeader
                {...headerProps}
                type={ItemTypes.media}
              />
            )}
            renderItem={(item) => renderItem(item, ItemTypes.media)}
            selectedItems={getSelectedItems(ItemTypes.media)}
            title={props.t('HomePage.titles.image')}
          />
        )}
      </SimpleEditPage.Tab>
    </SimpleEditPage>
  );
});

export default {
  component: HomePage,
  onInitialize: (id: number): Promise<any> => (
    HomePages
      .fetchOne(id)
      .then(({ data }) => data.home_page)
  ),
  onSave: (homePage: HomePageType): Promise<any> => (
    HomePages
      .save(homePage)
      .then(({ data }) => data.home_page)
  )
};
