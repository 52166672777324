// @flow

import type { NameType as NameTypeType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type Node } from 'react';
import { Form } from 'semantic-ui-react';

type Props = EditContainerProps & Translateable & {
  item: NameTypeType
};

const NameTypeForm = (props: Props): Node => (
  <Form.Input
    error={props.isError('name')}
    label={props.t('NameType.labels.name')}
    onChange={props.onTextInputChange.bind(this, 'name')}
    required={props.isRequired('name')}
    value={props.item.name || ''}
  />
);

export default NameTypeForm;
