// @flow

import type { MediaContent, Publication, RecordAssociation } from '@archnet/shared';
import _ from 'underscore';
import MediaSources from '../constants/MediaSources';
import MediaTypes from '../constants/MediaTypes';
import CopyrightRestrictions from '../resources/CopyrightRestrictions.json';

type Item = {
  media_record_associations: Array<RecordAssociation>
};

type PreviewItem = {
  content_preview_url?: string,
  primary_image?: {
    child: MediaContent
  }
};

/**
 * Returns the content URL for the passed media record.
 *
 * @param media
 *
 * @returns {*|string}
 */
const getContentUrl = (media: MediaContent): ?string => (isExternal(media) ? getExternalUrl(media) : media.content_url);

/**
 * Returns the copyright restriction value for the passed media_content or publication.
 *
 * @param item
 *
 * @returns {*}
 */
const getCopyrightRestrictionView = (item: MediaContent | Publication) => {
  let value;

  if (item && !_.isEmpty(item.donation_record_associations)) {
    const donation = _.first(item.donation_record_associations).parent;
    const viewValue = _.findWhere(CopyrightRestrictions, { value: donation?.copyright_restriction });

    if (viewValue) {
      value = viewValue.text;
    }
  }

  return value;
};

/**
 * Returns the external source preview URL.
 *
 * @param media
 *
 * @returns {*}
 */
const getExternalPreview = (media: MediaContent): ?string => {
  let preview;

  if (media.external_source === MediaSources.youtube) {
    preview = `https://img.youtube.com/vi/${media.external_id}/0.jpg`;
  }

  return preview;
};

/**
 * Returns the external content URL.
 *
 * @param media
 *
 * @returns {*}
 */
const getExternalUrl = (media: MediaContent): ?string => {
  let url;

  if (media.external_source === MediaSources.youtube) {
    url = `https://www.youtube.com/embed/${media.external_id}`;
  }

  return url;
};

/**
 * Returns the image URL for the passed item.
 *
 * @param item
 *
 * @returns {*|string|null}
 */
const getImageUrl = (item: any, attribute = 'content_iiif_url'): ?string => {
  if (!item) {
     return null;
  }

  const primaryImage = _.find(item.media_record_associations, (ra) => ra.primary && !ra._destroy) || item.primary_image;
  if (primaryImage?.child) {
    return getImageUrl(primaryImage.child, attribute);
  }

  if (isVideo(item) && isExternal(item)) {
    return getExternalPreview(item);
  }

  return item[attribute];
};

/**
 * Returns the preview image URL for the passed item.
 *
 * @param item
 *
 * @returns {*}
 */
const getPreviewUrl = (item: PreviewItem): ?string => getImageUrl(item, 'content_preview_url');

/**
 * Returns the array of media_contents items for the passed record.
 *
 * @param item
 *
 * @returns {[]}
 */
const getMediaContents = (item: Item): Array<MediaContent> => {
  const mediaContents = [];

  if (!_.isEmpty(item.media_record_associations)) {
    mediaContents.push(..._.map(item.media_record_associations, (ra) => ra.child));
  }

  return mediaContents;
};

/**
 * Returns true if the passed media record contains external content.
 *
 * @param media
 *
 * @returns {boolean}
 */
const isExternal = (media: MediaContent): boolean => media.external_id && media.external_source;

/**
 * Returns true if the passed media content is an image. We'll first check the content prop in case the user is creating
 * a new image and has not yet selected the media_type.
 *
 * @param media
 *
 * @returns {boolean}
 */
const isImage = (media: MediaContent): boolean => {
  if (media && media.content_type) {
    return media.content_type.indexOf('image') >= 0;
  }

  return media?.media_type?.kind === MediaTypes.kind.image || media?.type === 'Image';
};

/**
 * Returns true if the passed media content is an video. We'll first check the content prop in case the user is creating
 * a new video and has not yet selected the media_type.
 *
 * @param media
 *
 * @returns {boolean}
 */
const isVideo = (media: MediaContent): boolean => {
  if (media && media.external_source) {
    return media.external_source === MediaSources.youtube;
  }

  if (media && media.content_type) {
    return media.content_type.indexOf('video') >= 0;
  }

  return media && media.media_type && media.media_type.kind === MediaTypes.kind.video;
};

/**
 * Gets the first available site name for a media content record.
 * 
 * @param media
 * 
 * @returns {*|string|null}
 */
const getSiteName = (media: MediaContent) => {
  if (media.sites && media.sites[0]) {
    return media.sites[0];
  }

  if (media.site_record_associations && media.site_record_associations[0]) {
    return media.site_record_associations[0].parent.name;
  }

  return null;
}

/**
 * Gets the first available authority name for a media content record.
 * 
 * @param media
 * 
 * @returns {*|string|null}
 */
const getAuthorityName = (media: MediaContent) => {
  if (media.authorities && media.authorities[0]) {
    return media.authorities[0];
  }

  if (media.authority_record_associations && media.authority_record_associations[0]) {
    return media.authority_record_associations[0].parent.name;
  }

  return null;
}

/**
 * Produces alt-text for the passed media content record.
 * 
 * @param media
 * 
 * @returns {string}
 */
const getAltText = (media: MediaContent): string => (
  _.compact([
    getSiteName(media) || getAuthorityName(media) || null,
    media.altText || null,
  ]).join(' - ')
);

/**
 * Combines an array of strings or falsy values into
 * a hyphen separated single string.
 * 
 * @param strings 
 * @returns {string} 
 */
const combineStrings = (strings: Array<string | null | undefined>) => (
  _.compact(strings).join(' - ')
);

/**
 * Sets the selection state for the first file in the passed array.
 * @param files
 *
 * @returns {{
 *   content_preview_url: string,
 *   content_type,
 *   content_remove: boolean,
 *   content_url: string,
 *   content_iiif_url: string,
 *   content_thumnail_url: string,
 *   content: *,
 *   content_download_url: string,
 *   url_is_local: boolean
 * }}
 */
const getSelectionState = (files) => {
  const file = _.first(files);
  const url = URL.createObjectURL(file);

  return {
    content: file,
    content_download_url: null,
    content_iiif_url: url,
    content_preview_url: url,
    content_thumnail_url: url,
    content_type: file.type,
    content_url: url,
    content_remove: false,
    url_is_local: true
  };
};

export default {
  combineStrings,
  getAltText,
  getContentUrl,
  getCopyrightRestrictionView,
  getExternalPreview,
  getExternalUrl,
  getImageUrl,
  getMediaContents,
  getPreviewUrl,
  isExternal,
  isImage,
  isVideo,
  getSelectionState
};
