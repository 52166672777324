// @flow

import _ from 'underscore';

import type { NameType as NameTypeType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming name type records.
 */
class NameType {
  PAYLOAD_KEYS: Array<string> = [
    'name'
  ];

  /**
   * Returns the passed name type as a dropdown option.
   *
   * @param nameType
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(nameType: NameTypeType): Option {
    return {
      key: nameType.id,
      value: nameType.id,
      text: nameType.name
    };
  }

  /**
   * Returns the name type to be sent to the server on POST/PUT requests.
   *
   * @param nameType
   *
   * @returns {*}
   */
  toPayload(nameType: NameTypeType): any {
    return {
      name_type: _.pick(nameType, this.PAYLOAD_KEYS)
    };
  }
}

export default (new NameType(): NameType);
