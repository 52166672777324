// @flow

import type { Translateable } from '@archnet/shared';
import React, {
  type AbstractComponent,
} from 'react';
import Geocode from 'react-geocode';
import { withTranslation } from 'react-i18next';
import { Button, Segment } from 'semantic-ui-react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import './GeographyMap.css';

type Props = Translateable & {
  address: string,
  latitude: number,
  longitude: number,
  onError?: ({ message: string }) => void,
  onAddressSuccess: (latitude: number, longitude: number) => void,
  onCoordinatesSuccess: (result: any) => void,
  onMarkerDrag: ({ lat: number, lng: number }) => void
};

const GeographyMap = (props: Props) => {
  const mapStyle = { width: '100%', height: '400px' };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_2
  });

  const center = {
    lat: Number(props.latitude) || 0,
    lng: Number(props.longitude) || 0
  };

  return isLoaded ? (
    <>
      <Segment id='map-segment'>
        <GoogleMap
          mapContainerStyle={mapStyle}
          onDragEnd={props.onMarkerDrag}
          center={center}
          zoom={12}
        />
      </Segment>
      <Button
        content={props.t('GeographyMap.buttons.coordinatesAddress')}
        icon='map marker alternate'
        onClick={() => (
          Geocode
            .fromLatLng(props.latitude, props.longitude)
            .then(props.onCoordinatesSuccess, props.onError)
        )}
        primary
      />
      <Button
        content={props.t('GeographyMap.buttons.addressCoordinates')}
        icon='home'
        onClick={() => (
          Geocode
            .fromAddress(props.address)
            .then(props.onAddressSuccess, props.onError)
        )}
        primary
      />
    </>
  ) : <div />;
};

GeographyMap.defaultProps = {
  onError: () => {
  }
};

export default (withTranslation()(GeographyMap): AbstractComponent<any>);
